<template>
    <div class="terms-form">
        Ao clicar em concordar e continuar, você concorda com nossos <span class="blue">Termos de Uso</span>, <span class="blue">Política de Dados</span> e <span class="blue">Política de Cookies</span>.
    </div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
    setup(props, { emit }) {

        return{}
    },
};
</script>


<style lang="scss" scoped>
.terms-form {
    width: 100%;
    font-family: "Roboto";
    color: #2D313D;
    font-size: 14px;
    font-weight: 400;
    max-width: 60ch;
    margin-top: 28px;
    .blue{
        color: #2F80ED;
        cursor: not-allowed;
    }
}

::v-deep(.std-bottom) {
    border: 1px solid #ced4da;
}
</style>