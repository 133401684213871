<template>
    <div class="password-form">
        <!-- PASSWORD -->
        <div class="container-gap">
            <span class="p-input-icon-right">
                <i 
                    class="pi pi-eye" 
                    v-tooltip.top="{ value: viewPassword ? 'Ocultar senha' : 'Mostrar senha' }"
                    @click="viewPassword = !viewPassword"
                />
                <InputText
                    :value="password"
                    class=""
                    :class="conditionPass.class"
                    :type="viewPassword ? 'text' : 'password'"
                    placeholder="Senha"
                    :maxlength="20"
                    @input="(e) => $emit('update:password', e.target.value)"
                    @keypress.enter="$emit('keypress-enter')"
                />
            </span>
            <ul v-if="conditionPass.status">
                <li class="p-invalid" v-for="(pass, index) in msgErros.password" :key="`${pass}-${index}`">{{pass}}</li>
            </ul>

            <!-- CONFIRM PASSWORD -->
            <span class="p-input-icon-right">
                <i 
                    class="pi pi-eye"
                    v-tooltip.top="{ value: viewConfirm ? 'Ocultar senha' : 'Mostrar senha' }"
                    @click="viewConfirm = !viewConfirm" 
                />
                <InputText
                    :value="confirm"
                    class=""
                    :class="conditionConfirm.class"
                    :type="viewConfirm ? 'text' : 'password'"
                    placeholder="Confirmar senha"
                    :maxlength="20"
                    @input="(e) => $emit('update:confirm', e.target.value)"
                    @keypress.enter="$emit('keypress-enter')"
                />
            </span>
            <ul v-if="conditionConfirm.status" :style="{ marginBottom: 0 }">
                <li class="p-invalid" v-for="confirm in msgErros.confirm">{{confirm}}</li>
            </ul>
         </div>

        <!-- BAR FORCE PASSWORD -->
        <ForcePassword :password="password" />        
    </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import ForcePassword from "./ForcePassword"

export default {
    props: ["password", "confirm", "msgErros"],
    emits: ["update:password", "update:confirm", "keypress-enter"],
    components: {
        ForcePassword,
    },
    setup(props, { emit }) {
        const viewPassword = ref(false);
        const viewConfirm = ref(false);

        const conditionPass = computed(() => {
            if( props.msgErros.password.length ){      
                if( props.msgErros.password.includes("Senha deve possuir no mínimo 6 caracteres." ) && props.password.length < 6 ){
                    return { status: true, class: 'p-invalid' }
                }

                if(( props.msgErros.password.includes("Senha deve ser informado" ) && !props.password )){
                    return { status: true, class: 'p-invalid' };
                }

            }
            return { status: false, class: '' };
        })
        
        const conditionConfirm = computed(() => {
            if( props.msgErros.confirm.length ){       
                if( props.msgErros.confirm.includes("Confirmação de senha deve possuir no mínimo 6 caracteres.") && props.confirm.length < 6 ){
                    return { status: true, class: 'p-invalid' }
                }
                if( props.msgErros.confirm.includes("Confirmação de senha deve ser igual à senha.") && ( props.password != props.confirm )){
                    return { status: true, class: 'p-invalid' }
                }
                if( props.msgErros.confirm.includes("Confirmação de senha deve ser informado.") && !props.confirm ){
                    return { status: true, class: 'p-invalid' }
                }
            }
            return { status: false, class: 'correction-bordertop' }
        })

        return{
            viewPassword,
            viewConfirm,
            conditionPass,
            conditionConfirm,
        }
    },
};
</script>


<style lang="scss" scoped>
.password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto";
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px 0;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        /* letter-spacing: 1px; */
        margin-bottom: 28px;
        margin-top: 8px;
    }
    ul{
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: 0px;
        margin-left: 6px;
        margin-bottom: 8px;
        list-style: none;
        padding: 0;
        li{
            margin: 0;
            padding: 0;
            font-size: 12px;
        }
    }
    .container-gap{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    &::placeholder{
        color: #BDBDBD;
    }
}

::v-deep(.std-bottom) {
    border-top: 1px solid white;
}

::v-deep(.pi-eye) {
    cursor: pointer;
    right: 16px !important;
}
::v-deep(.correction-borderbottom) {
    border-bottom: 1px solid #F2F2F2;
}
::v-deep(.correction-bordertop) {
    border-top: 1px solid #F2F2F2;
}
</style>