import patientAccountService from "../../../core/services/patientAcountService";
import { 
    RegisterRequest,
    SaveInfoAfterUserCloseBrowserPageOneRequest,
} from "../../../core/grpc/generated/patientAccount_pb";

const Register = async (formData) => {
    try {
        const req = new RegisterRequest();
        req.setFirstname(formData.first_name);
        req.setLastname(formData.last_name);
        req.setEmail(formData.email);
        req.setCountrycode(formData.country_code);
        req.setPhone(formData.phone);
        req.setPassword(formData.password);
        req.setConfirm(formData.confirm_pass);

        const res = await patientAccountService.register(req);

        return res;
    } catch (error) {
        console.log(error);
    }
}

const SaveInfo = async (formData) => {
    try {
        const req = new SaveInfoAfterUserCloseBrowserPageOneRequest();
        req.setFirstname(formData.first_name);
        req.setLastname(formData.last_name);
        req.setEmail(formData.email);
        req.setCountrycode(formData.country_code);
        req.setPhone(formData.phone);

        const res = await patientAccountService.saveInfo(req);

        console.log(res);        
        return res;
        // localStorage.setItem('formData', JSON.stringify(formData));
    } catch (error) {
        console.log(error);
    }
}

const PhoneConfirmation = async (phone) => {
    // console.log(phoneSending.value);
    const request = new SendPhoneConfirmationRequest();
    request.setPhone(phone);

    const response = await professionalAccountService.sendPhoneConfirmation(
      request
    );

    if (response.success == true) {
      router.push("/account/phone/confirmation");
    }
};



export {
    Register,
    SaveInfo,
    PhoneConfirmation,
}