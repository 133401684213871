<template>
    <div class="phone-form">
        <div class="container-gap">
            <Dropdown
                v-model="postalCode"
                class=""
                :class="conditionDDI.class"
                :options="countryList"
                optionLabel="name"
                placeholder="Selecione o país"
                @change="updateCountryCode"
            />
            <div class="errormessages" v-if="conditionDDI.status">
                <p class="p-invalid">{{msgErros.countryCode[0]}}</p>
            </div>

            <!-- INPUT TO MASK NUMBER -->
            <InputMask
                v-if="countryCode === '+55'"
                v-model="phone"
                :class="conditionPhone.class"
                mask="( 99 ) 9 9999-9999"
                placeholder="( xx ) x xxxx - xxxx"
                @change="updateModel"
            />
            <InputMask
                v-else-if="countryCode === '+1'"
                v-model="phone"
                :class="conditionPhone.class"
                mask="( 999 ) 99999-9999"
                placeholder="( xxx ) xxxxx - xxxx"
                @change="updateModel"
            />
            <InputText v-else :class="conditionPhone.class" v-model="phone" placeholder="Telefone" disabled />    
            <!-- END INPUT TO MASK NUMBER -->

            <div class="errormessages" v-if="conditionPhone.status">
                <p class="p-invalid">{{ msgErros.telefone[0] }}</p>
            </div>        
        </div>



        <div class="messages">
            <span>Usaremos seu número para segurança da conta.</span>
            <span>Você receberá notificações de segurança por SMS.</span>
            <span>Ele não ficará visível para outras pessoas.</span>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";


const countryList = [
    { name: "Brasil +55", code: "+55" },
    { name: "Estados Unidos +1", code: "+1" },
];

export default {
    props: ["modelValue", "countryCode", "msgErros"],
    emits: ["update:modelValue", "update:countryCode", "callSaveInfo"],
    setup(props, { emit }) {
        const phone = ref("");
        const phone_used = ref("");
        const postalCode = ref({ name: "Brasil +55", code: "+55" });
        
        const conditionDDI = computed(() => {
            if( props.msgErros.countryCode[0] === "DDI deve ser informado." && !postalCode.value.code ){
                return { status: true, class: "p-invalid" };
            }
            return { status: false, class: "" }
        })

        const conditionPhone = computed(() => {
            if( props.msgErros.telefone[0] === "Telefone deve ser informado." && !phone.value ){
                return { status: true, class: "p-invalid" };
            }
            if( props.msgErros.telefone[0] === "Telefone já está em uso." && ((phone.value === phone_used.value) || !props.modelValue) ){
                return { status: true, class: "p-invalid" };
            }
            return { status: false, class: "correction-bordertop" }
        })

        const updateModel = (e) => {
            const number = e.target.value;
            emit("update:modelValue", number.replace(/[^0-9]/g, ""));
            emit("callSaveInfo");
        }

        const updateCountryCode = (e) => {
            emit("update:countryCode", e.value.code);
        }

        watch(() => props.countryCode, (newValue, oldValue) => {
            phone.value = ""
        });

        watch(() => props.msgErros.telefone, (newValue, oldValue) => {
            const find = newValue[0] === "Telefone já está em uso.";
            
            if(find){
                phone_used.value = phone.value;
            }
        });

        return{
            phone,
            phone_used,
            postalCode,
            countryList,
            updateModel,
            updateCountryCode,
            conditionDDI,
            conditionPhone,
        }
    },
};
</script>


<style lang="scss" scoped>
.phone-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Roboto", sans-serif;
    
    .messages{
        display: flex;
        flex-direction: column;
        margin: 4px;
        color: #2D313D;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .3px;
        margin-bottom: 18px;
        margin-top: 6px;
    }
    .errormessages{
        font-size: 12px;
        margin-top: 4px;
        margin-left: 6px;
    }
    .container-gap{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}


::v-deep(.p-inputtext) {
    color: #2D313D;
    padding: 0 16px;
    height: 50px;
    &::placeholder{
        color: #BDBDBD;
    }
}
::v-deep(.p-dropdown-label) {
    display: flex;
    align-items: center;
    color: #2D313D;
    padding: 0 16px;
    height: 48px;
    
    &::placeholder{
        color: #BDBDBD;
    }
}

::v-deep(.p-dropdown) {
    display: flex;
    align-items: center;
    color: #2D313D;
    height: 50px;
    font-weight: 600 !important;
    &::placeholder{
        color: #BDBDBD;
    }

}


::v-deep(.p-dropdown-trigger) {
    margin-right: 6px;
    
    span{
        font-size: 12px;
    }
}
::v-deep(.p-invalid) {
    border-color: #f44336 !important;
}
</style>