<template>
    <div class="form">
        <div class="header">
            <h1>Cadastro</h1>
        </div>
        
        <Pagination :value="1" :size="5" />

        <h1>Criar Conta</h1>
        <div class="limit">
            <FirstNameForm v-model="formData.first_name" :msgErros="msgErros" />
            <LastNameForm v-model="formData.last_name" :msgErros="msgErros" />
            <EmailForm 
                v-model="formData.email" 
                :msgErros="msgErros"
            />
            <PhoneForm 
                v-model="formData.phone" 
                v-model:countryCode="formData.country_code"
                :msgErros="msgErros"
            />
            <PasswordForm 
                v-model:password="formData.password" 
                v-model:confirm="formData.confirm_pass" 
                :msgErros="msgErros"
                @keypress-enter="submit"
            />
            <TermsForm />
        </div>
        <div class="flex w-full justify-content-center mt-2">
            <Button class="btn-submit" label="Concordar e continuar" @click="submit" :disabled="loading">
                <span class="p-button-label">Concordar e continuar</span>
                <i class="pi pi-spin pi-spinner" v-if="loading" />
            </Button>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, watch } from "vue";
import Pagination from "../../components/Pagination";
import FirstNameForm from "./components/FirstNameForm";
import LastNameForm from "./components/LastNameForm";
import EmailForm from "./components/EmailForm";
import PhoneForm from "./components/PhoneForm";
import PasswordForm from "./components/PasswordForm";
import TermsForm from "./components/TermsForm";
import { useRouter } from "vue-router";
import { 
    Register,
    SaveInfo,
} from "./actions";

let router = useRouter();
const msgErros = ref({
    firstName: [],
    lastName: [],
    email: [],
    countryCode: [],
    telefone: [],
    password: [],
    confirm: [],
})
const loading = ref(false);

const formData = reactive({
    first_name: "",
    last_name: "",
    email: "",
    country_code: "+55",
    phone: "",
    password: "",
    confirm_pass: "",
})

const submit = async () => {    
    loading.value = true;
    const res = await Register(formData);
    const data = JSON.parse(res.data);

    if(res.success){
        if('token' in data){
            localStorage.setItem("token", data.token);
            localStorage.setItem("email", formData.email);
            localStorage.setItem("phone", formData.country_code + ' ' + formData.phone);

            // after verify bug router.push no focus input
            // router.push("/account/phone/confirmation");
            window.location.href = "/account/phone/confirmation";
        }
    }else{
        const erros = {
            firstName: [],
            lastName: [],
            email: [],
            countryCode: [],
            telefone: [],
            password: [],
            confirm: [],
        }

        if('firstName' in data){ erros.firstName = data.firstName; }
        if('lastName' in data){ erros.lastName = data.lastName; }
        if('email' in data){ erros.email = data.email; }
        if('countryCode' in data){ erros.countryCode = data.countryCode; }
        if('telefone' in data){ erros.telefone = data.telefone; }
        if('password' in data){ erros.password = data.password; }
        if('confirm' in data){ erros.confirm = data.confirm; }
        msgErros.value = erros;          
    }
    loading.value = false;
};

watch(() => 
    formData.country_code,
(newValue, oldValue) => {
    formData.phone = "";
});

watch(() => [
    formData.email,
    formData.phone,
], async () => {
    const objForm = {...formData};
    delete objForm.password;
    delete objForm.confirm_pass;

    SaveInfo(objForm)
});
</script>


<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    width: 478px;
    background: white;
    margin: 0 auto;
    margin-top: 30px;
    border-radius: 8px;
    padding-bottom: 32px;

    h1{
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        text-align: center;
        margin: 0;
        margin-bottom: 30px;
    }

    .header{
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #F2F2F2;
        text-align: center;
        h1{
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            margin: 0;
        }
    }
    .limit{
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100% !important;
        padding: 0 32px;
    }
    .btn-submit{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        min-width: 230px;
        height: 42px !important;
    }
}

@media (max-width: 580px){
    .form{
        width: 100%;
    }
}

:deep() {
    .p-button {
        margin-top: 10px;
        padding: 20px 80px;
        width: max-content;
    }
    input {
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        font-family: Roboto, sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        &:disabled{
            background: white;
            /* opacity: 1; */
            cursor: not-allowed !important;
        }
        &::placeholder{
            color: #BDBDBD;       
        }
    }
    .p-dropdown{
        border-radius: 8px;
        font-family: Roboto, sans-serif;
        font-size: 14px !important;
        font-weight: 400 !important;
        letter-spacing: 1px !important;
        &:disabled {
            background: white;
            /* opacity: 1; */
            cursor: not-allowed !important;
        }
        &::placeholder {
            color: #BDBDBD;       
        }
    }
}
</style>